@import url("https://use.typekit.net/jmk3xov.css");

canvas {
  position: fixed;
  top: 0;
  left: 0;
}

:root {
  --dark-bg: rgba(15, 15, 15, 0.95);
  --spacing: 50px;

  font-family: brandon-grotesque, sans-serif;
  font-weight: 400;
  font-style: normal;
}

main {
  width: 100vw;
  color: white;
  z-index: 99;
  position: absolute;
  width: 100%;
  margin: 0px auto;
  padding: 120px 0px;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

h1,
h2,
h3,
blockquote {
  font-family: elevon, sans-serif;
  font-weight: 700;
  font-style: normal;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
}

header {
  background: var(--dark-bg);
  grid-column: 2 / span 5;
  font-size: 2.5rem;
  padding: 2rem;
  margin-bottom: var(--spacing);
}

section {
  grid-column: 2 / span 8;
  padding: 1rem;
  background: var(--dark-bg);
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: var(--spacing);
}

blockquote {
  margin: 0;
  padding: 0;
  grid-column: 2 / span 9;
  margin-bottom: var(--spacing);
}

blockquote p {
  color: black;
  background-color: white;
  font-size: 4rem;
  display: inline;
  line-height: 1;
}

.left {
  grid-column: 6 / 12;
}

.covid {
  text-align: center;
  grid-column: 2 / span 5;
}

.container {
  display: flex;
  justify-content: space-between;
}

a {
  color: inherit;
}

html {
  scroll-behavior: smooth;
}

.scroll {
  position: fixed;
  width: 100%;
  left: 50%;
  bottom: 40px;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: lightgrey;
}

table {
  width: 100%;
  text-align: center;
}

.footer {
  text-align: right;
}
